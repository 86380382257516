<template><div><h1 id="post-install" tabindex="-1"><a class="header-anchor" href="#post-install"><span>Post Install</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Install <a href="https://github.com/death7654/Chrultrabook-Tools/releases" target="_blank" rel="noopener noreferrer">Chrultrabook Tools</a> for fan controls, keyboard backlight controls, system information and more.</p>
</div>
<h2 id="windows" tabindex="-1"><a class="header-anchor" href="#windows"><span>Windows</span></a></h2>
<h3 id="drivers-and-qol-improvements" tabindex="-1"><a class="header-anchor" href="#drivers-and-qol-improvements"><span>Drivers and QOL improvements</span></a></h3>
<ul>
<li>Compress the install with <code v-pre>compact.exe /compactos:always</code> in a Command Prompt with admin privileges if necessary.</li>
<li>Install <a href="https://coolstar.org/chromebook/windows-install.html" target="_blank" rel="noopener noreferrer">CoolStar's drivers</a> if you haven't already</li>
<li>Buy audio and Thunderbolt/USB4 drivers (if needed), see <a href="https://coolstar.org/chromebook/driverlicense/login.html" target="_blank" rel="noopener noreferrer">CoolStar's driver portal</a></li>
</ul>
<h3 id="celes-post-install-workaround-windows" tabindex="-1"><a class="header-anchor" href="#celes-post-install-workaround-windows"><span>CELES Post Install Workaround (Windows)</span></a></h3>
<ol>
<li>Boot the Windows install media you used to install Windows.</li>
<li>Click &quot;Repair my computer&quot; and select the Command Prompt option.</li>
<li>Type <code v-pre>bcdedit /set {default} useplatformclock true</code> and press Enter.</li>
<li>Reboot</li>
</ol>
<h2 id="linux" tabindex="-1"><a class="header-anchor" href="#linux"><span>Linux</span></a></h2>
<h3 id="general-qol-fixes" tabindex="-1"><a class="header-anchor" href="#general-qol-fixes"><span>General QOL Fixes</span></a></h3>
<ul>
<li>Run the <a href="https://github.com/WeirdTreeThing/chromebook-linux-audio" target="_blank" rel="noopener noreferrer">audio script</a> if you haven't already.</li>
<li>Run the <a href="https://github.com/WeirdTreeThing/cros-keyboard-map" target="_blank" rel="noopener noreferrer">cros-keyboard-map</a> script to remap top row FX keys.</li>
</ul>
<h3 id="fixing-usb-c-on-tigerlake-and-alderlake" tabindex="-1"><a class="header-anchor" href="#fixing-usb-c-on-tigerlake-and-alderlake"><span>Fixing USB C on TigerLake and AlderLake</span></a></h3>
<ol>
<li>
<p>Create a file called <code v-pre>chromebook-usbc.service</code> file in <code v-pre>/etc/systemd/system/</code></p>
</li>
<li>
<p>Paste the following contents into the file:</p>
<div class="language-txt line-numbers-mode" data-highlighter="prismjs" data-ext="txt" data-title="txt"><pre v-pre class="language-txt"><code><span class="line">[Unit]</span>
<span class="line">Description=Enable USB-C on chromebook</span>
<span class="line"></span>
<span class="line">[Service]</span>
<span class="line">Type=oneshot</span>
<span class="line">RemainAfterExit=yes</span>
<span class="line">ExecStartPre=/sbin/modprobe -r -a cros-ec-typec intel-pmc-mux</span>
<span class="line">ExecStart=/sbin/modprobe -a intel-pmc-mux cros-ec-typec</span>
<span class="line"></span>
<span class="line">[Install]</span>
<span class="line">WantedBy=multi-user.target</span>
<span class="line"></span></code></pre>
<div class="line-numbers" aria-hidden="true" style="counter-reset:line-number 0"><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div><div class="line-number"></div></div></div></li>
<li>
<p>Enable the systemd service by typing <code v-pre>systemctl enable --now chromebook-usbc.service</code>, then it should work.</p>
</li>
</ol>
<h3 id="celes-post-install-workaround-possible-linux" tabindex="-1"><a class="header-anchor" href="#celes-post-install-workaround-possible-linux"><span>CELES Post Install Workaround Possible (Linux)</span></a></h3>
<p>If you experience issues in applications such as Parsec, or encounter disruptive freezes, adding the kernel parameters <code v-pre>clocksource=hpet hpet=force</code> may fix your problem. The following instructions assume you are using GRUB, and will be different for other bootloaders. Do your own research on how to set kernel parameters in your bootloader if these do not apply.</p>
<ol>
<li>Edit <code v-pre>/etc/default/grub</code> with your preferred text editor (e.g. nano).</li>
<li>Add <code v-pre>clocksource=hpet hpet=force</code> inside of GRUB_CMDLINE_LINUX_DEFAULT and save your changes. If you exclude either parameter, this will not work. Use sudo, su, or doas if necessary.</li>
<li>Type <code v-pre>grub-mkconfig -o /boot/grub/grub.cfg</code> or <code v-pre>update-grub</code> into a terminal and press Enter. Use sudo, su, or doas if necessary.</li>
<li>Reboot</li>
</ol>
<h2 id="macos" tabindex="-1"><a class="header-anchor" href="#macos"><span>macOS</span></a></h2>
<ul>
<li><a href="https://dortania.github.io/OpenCore-Post-Install/cosmetic/verbose.html#macos-decluttering" target="_blank" rel="noopener noreferrer">Remove Verbose</a></li>
<li><a href="https://dortania.github.io/OpenCore-Post-Install/cosmetic/gui.html#setting-up-opencore-s-gui" target="_blank" rel="noopener noreferrer">OpenCore GUI Setup</a></li>
<li>Disable <code v-pre>DevirtualiseMmio</code> if you are on CometLake (10th gen)</li>
</ul>
</div></template>


